import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: '1',
        title: 'We are<br /><b>Team</b> of great dreamers',
    },
    {
        icon: '2',
        title: 'Driven by constant <b>Innovation</b> and<br />Strive for <b>Perfection</b>.',
    },
    {
        icon: '3',
        title: 'With the Right <b>Partner</b>,<br />You Can Make a <b>Difference</b>.',
    },
]
const ServiceSix = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="content">
                                    <h4 className="title" dangerouslySetInnerHTML={{__html: val.title}}></h4>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceSix;