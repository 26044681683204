import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabOne = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-5">
                                    <img className="radius-small" src='./images/tab/tabs-01.png' alt="Corporate React Template" />
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Salesforce</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Veeva/Veeva Vault</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Vlocity</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                        <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>
                                                            ‣ We deliver comprehensive managed services, consulting, implementation, development, integration using Salesforce.com technologies to implement, maintain and optimise strategic value adaptable applications.<br></br>
                                                            ‣ Having a well-defined CRM Implementation Strategy will ensure 100% success of the implementation process.<br></br>
                                                            ‣ Salesforce provides rich analytic capabilities as well as a huge variety of potential apps and integrations making it the most popular cloud-based software provider.<br></br>
                                                            ‣ We have certified expertise and years of experience across countless Salesforce clouds, partners, and applications.<br></br>
                                                            ‣ Our deep expertise with Salesforce suite products successfully.<br></br>
                                                        </p>

                                                        <p>Accelerate Towards your next with Salesforce and RIS<br></br>‣ Sales cloud<br></br>‣ Service cloud<br></br>‣ Experience (Community) cloud<br></br>‣ Marketing cloud<br></br>‣ Health cloud<br></br>‣ Financial service cloud<br></br>‣ Education cloud<br></br>‣ Commerce cloud<br></br>‣ App Cloud<br></br>‣ AI & IOT Cloud<br></br>‣ CPQ</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                    <p>
                                                        ‣ Veeva Systems is most trusted by the global life sciences industry for cloud-based CRM solutions. Delivering best-in-class customer engagement, Veeva CRM aligns all customer-facing resources and interactions to deliver a personalized, coordinated customer experience across channels.<br></br>
                                                        ‣ RIS Veeva practice provides critical insights to clients with the competences of digital interactions, personalized engagement throughout the customer journey.<br></br>
                                                        ‣ We help our clients to achieve their goals using our experience and RIS best practices. Find out more.<br></br>
                                                        ‣ Veeva Vault is a true cloud enterprise content management platform and suite of applications specifically built for life sciences.<br></br>
                                                        ‣ Veeva Vault’s unique ability to manage both content and data in a single platform enables organizations to quickly deploy powerful applications that manage end-to-end processes with related content, data and workflows.<br></br>
                                                        ‣ The Veeva Vault platform is architected to meet among the most rigorous usability, scalability, performance, validation and security requirements.<br></br>
                                                    </p>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                    <p>
                                                        ‣ Vlocity is a leading provider of industry-specific cloud and mobile software for the world’s top companies in communications, media and entertainment, energy and utilities, insurance, health, and government organization.<br></br>
                                                        ‣ With Salesforce Customer 360 and Vlocity, our customers have the most advanced industries platform for digital transformation, as well as tools and expert guidance completely tailored to their specific needs.<br></br>
                                                        ‣ Salesforce Industries (Vlocity) is a one-stop solution for every business to make a move towards digital transformation.<br></br>
                                                    </p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabOne
