import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: '1',
        title: ' Our Solution',
        description: 'Design and implementation strategy is agile and configurable to suit each organization’s unique needs.'
    },
    {
        icon: '2',
        title: 'Our Mission',
        description: 'We make our customer as a Family to give a meaningful in the moments that matter most and travel eternally. We are there for you to help and support throughout your journey at the right time in the right channel and the content is always relevant to the receiving audience, be it your Employees, Partners or Customers.'
    },
    {
        icon: '3',
        title: 'Our Vision',
        description: 'We bring your business a new life and Fast growth with our Challenging and creative work. Our main motto is to achieve appropriate Decisions, Interaction and Successful deals with our customer eternally.'
    },
]
const ServiceSeven = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="content">
                                    <h4 className="title">
                                        <Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link>
                                    </h4>
                                    <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                                {/* <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div> */}
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceSeven;