import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import AboutFour from '../elements/about/AboutFour';
import AdvanceTabTwo from '../elements/advancetab/AdvanceTabTwo';
import CounterUpFour from '../elements/counterup/CounterUpFour';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import TeamFour from '../elements/team/TeamFour';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import ContactOne from '../elements/contact/ContactOne';



const Consulting = () => {
    return (
        <>
            <SEO title="Consulting" />
            <main className="page-wrapper">
                {/* <HeaderTopBar /> */}
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-transparent" />

                {/* Start Slider area  */}
                <div className="slider-area slider-style-2 variation-2  height-850 bg_image" data-black-overlay="5" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-10.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-xl-6">
                                <div className="inner text-start">
                                    <h1 className="title display-one">Get <br /> In Touch <br /> Right Now.</h1>
                                    <h6 className="tag-title">FOR BEST SOLUTIONS FOR YOUR BUSINESS</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}

                {/* Start Contact Area  */}
                <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}

                <FooterOne />
                {/* <Copyright /> */}
            </main>

        </>
    )
}

export default Consulting
