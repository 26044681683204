import React from 'react';
import AccordionTwentyOne from '../accordion/AccordionTwentyOne';
import AccordionTwentyThree from '../accordion/AccordionTwentyThree';
import AccordionTwentyTwo from '../accordion/AccordionTwentyTwo';
import AccordionTwo from '../accordion/AccordionTwo';


const PricingEight = () => {
    return (
        <div className="row row--15">

            {/* Start Accordion Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionTwentyTwo />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        < AccordionTwentyThree />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}
        </div>
    )
}
export default PricingEight;
