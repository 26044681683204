import React from 'react';
import { FiArrowRight } from "react-icons/fi";
const callToActionData = {
    title: "Logistics",
}

const CalltoActionThirteen = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper" style={{'opacity' : '0.7'}}>
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{callToActionData.title}</h2>
                                <h5>
                                ‣ Our solutions enable logistics companies to track and monitor their assets, vehicles, and shipments in real-time, providing accurate information about their location, condition, and estimated delivery times. This helps businesses improve operational efficiency, reduce delays, and enhance customer satisfaction.<br></br>
                                ‣ Our analytics tools leverage big data and machine learning algorithms to provide insights into supply chain processes, enabling logistics companies to forecast demand, identify bottlenecks, and optimize routes and inventory levels. This helps businesses make data-driven decisions, improve performance, and reduce costs.<br></br>
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionThirteen;