import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "2012",
        title: "Birth of RIS",
        description: "Rudhra Info Solutions founded at Nungambakkam, Chennai.",
        image: "timeline-01",
    },
    {
        id: "2",
        date: "2017",
        title: "Making the Family Bigger",
        description: "Our 2nd branch office with 80+ resources at Mannivakkam, Chennai.",
        image: "timeline-05",
    },
    {
        id: "3",
        date: "2019",
        title: "Growing Exponentially",
        description: "Inaugrated our new Office at Gachibowli, Hyderabad with 150+ resources.",
        image: "timeline-02",
    },
    {
        id: "4",
        date: "2020",
        title: "Making an Impact",
        description: "Opened our 4th and Largest branch at Whitefield, Bengaluru",
        image: "timeline-03",
    },
    {
        id: "5",
        date: "2022",
        title: "Going International",
        description: "Our first International Branch office opened at Wyoming, US",
        image: "timeline-04",
    },
    {
        id: "6",
        date: "2023",
        title: "Best Around the Globe",
        description: "We now have more than 310 resources at RIS and we are in our successful path as we started.",
        image: "timeline-06",
    },
]

const TimelineTwo = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-2 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h6 className="title" style={{fontSize:'15px'}}>{data.title}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{data.description}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
         </div>
    )
}
export default TimelineTwo;