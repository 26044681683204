import React from 'react'
import Logo from "../../elements/logo/Logo";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";

const Copyright = () => {
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="copyright-left">
                                    <div className="logo">
                                        <Logo 
                                            image={`${process.env.PUBLIC_URL}/images/logo/ris-logo-white.png`}
                                            image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                                        />
                                    </div>
                                    <br/>
                                    <p className="copyright-text">Copyrights @ 2023 Rudhra Info Solutions. All rights reserved. {new Date().getFullYear()}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="copyright-right text-center text-md-end">
                        <ul className="social-icon social-default icon-naked">
                                                <li><a href="https://www.facebook.com/rudhrainfosolutions/"><FiFacebook /></a></li>
                                                <li><a href="https://twitter.com/rudhrainfosolut?lang=en"><FiTwitter /></a></li>
                                                <li><a href="https://www.instagram.com/life_at_ris/"><FiInstagram /></a></li>
                                                <li><a href="https://in.linkedin.com/company/rudhra-info-solutions"><FiLinkedin /></a></li>
                                    </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Copyright;