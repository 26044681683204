import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from 'react-icons/fi';
import ServiceFive from '../service/ServiceFive';
import ServiceFour from '../service/ServiceFour';
import ServiceThree from '../service/ServiceThree';
import ServiceTwo from '../service/ServiceTwo';
import {Link} from "react-router-dom";

const callToActionData = {
    title: "Ready to Expand your Business with RIS?",
    subtitle: "Ready to Expand your Business with RIS?",
    btnText: "Go to Services ",
}

const CalltoActionOne = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default bg_image bg_image_fixed"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`}} data-black-overlay="7">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                            <ServiceFive/>
                                
                                {/* <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title">{callToActionData.title}</h2>
                                </ScrollAnimation> */}

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h6 className="subtitle">{callToActionData.subtitle}</h6>
                                </ScrollAnimation>
                                
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="call-to-btn">
                                        {/* <a className="btn-default" href="/services">{callToActionData.btnText}<FiArrowRight/></a> */}
                                        <Link className="btn-default" to="/services">{callToActionData.btnText}<FiArrowRight/></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionOne;