import React from 'react';
import AccordionFive from '../accordion/AccordionFive';
import AccordionFour from '../accordion/AccordionFour';
import AccordionOne from '../accordion/AccordionOne';
import AccordionSix from '../accordion/AccordionSix';
import AccordionThree from '../accordion/AccordionThree';
import AccordionTwo from '../accordion/AccordionTwo';


const PricingFive = () => {
    return (
        <div className="row row--15">
            
            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionFour />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionFive />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionSix />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

        </div>
    )
}
export default PricingFive;
