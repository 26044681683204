import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const AdvanceTabFive = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="col-lg-8">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/tabs-11.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/tabs-12.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/tabs-13.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1 right-align">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Excellence.</h4>
                                        <p className="description">Our pursuit of excellence drives us to constantly innovate and push the boundaries of what's possible, delivering top-notch solutions and services to our customers.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Teamwork.</h4>
                                        <p className="description">We believe that by working together, we can achieve more and deliver better results for our customers.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Empower.</h4>
                                        <p className="description">We are committed to empowering our customers by providing them support they need to succeed.</p>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabFive;
