import React from 'react';

const callToActionData = {
    title1: "Business Address",
    address1: "RUDHRA INFO SOLUTIONS INC 30 N GOULD ST STE 24722 SHERIDAN WY 82801-6317",
    URL1: "https://goo.gl/maps/VeAxJ6WXN4WbNLK18",
    title2: "Registered Office",
    address2: "1604 US HIGHWAY 130 NORTH BRUNSWICK, NEW JERSEY 08902",
    URL2: "https://goo.gl/maps/Yn9hknXCf1Yx6Y2d7",
}

const CalltoActionThree = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-2 bg_image"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-2.jpg)`}} data-black-overlay="4">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-6">
                        <div className="inner">
                            <div className="content text-start">
                                <h2 className="title">{callToActionData.title1}</h2>
                                <h6 className="subtitle">{callToActionData.address1}</h6>
                                <h6 className="subtitle theme-gradient"><a href={callToActionData.URL1}>Link to Google Map</a></h6>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="content text-start">
                                    <h2 className="title">{callToActionData.title2}</h2>
                                    <h6 className="subtitle">{callToActionData.address2}</h6>
                                    <h6 className="subtitle theme-gradient"><a href={callToActionData.URL2}>Link to Google Map</a></h6>
                                </div>
                        </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionThree;