import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Separator from "../elements/separator/Separator";
import BlogClassicData from '../data/blog/BlogList.json';
import PricingFive from '../elements/pricing/PricingFive';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import FooterOne from '../common/footer/FooterOne';
import { FiArrowRight } from 'react-icons/fi';
import PricingSix from '../elements/pricing/PricingSix';
import PricingSeven from '../elements/pricing/PricingSeven';
import PricingEight from '../elements/pricing/PricingEight';
import TabOne from '../elements/tab/TabOne';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const Services = () => {
    return (
        <>
            <SEO title="RIS Services" />
            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider area  */}
                <div className="slider-area slider-style-2 height-950 bg_image" data-black-overlay="4" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-6.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-start">
                                    <h1 className="title color-white">Our<br></br>Services.</h1>
                                    <div className="button-group mt--40 mt_sm--20">
                                        <button className="btn-default btn-icon" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Get In Touch<i className="icon"><FiArrowRight /></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}

                <div className="rwt-elements-area rn-section-gap">
                        <div className="container">
                        <Tabs>
                            <div className="rn-default-tab">
                                <div className="tab-button-panel">
                                    <TabList className="tab-button">
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'0.85vw'}}><a href="#EnterpriseApplication"><b>Enterprise Application</b></a></button>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'0.85vw'}}><a href="#DataAnalytics"><b>Data Analytics</b></a></button>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'0.85vw'}}><a href="#QualityEngineering"><b>Quality Engineering</b></a></button>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'0.85vw'}}><a href="#ApplicationServicesandModernization"><b>App Services and Modernization</b></a></button>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'0.85vw'}}><a href="#Cloud"><b>Cloud</b></a></button>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'0.85vw'}}><a href="#NextGen"><b>Next Gen</b></a></button>
                                            </div>   
                                        </Tab>
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                        </div>
                </div>

                <div id="EnterpriseApplication">
                    <Separator />
                </div>
                
                {/* Start Enterprise Area  */}
                <div className="rwt-pricing-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            // subtitle = "Pricing"
                                            title = "Enterprise Application"
                                            description = "Over the years, we have successfully completed several projects by leveraging Enterprise application platform capabilities to develop a full-proof implementation strategy and digitize mission-critical workflows."/>
                                </div>
                            </div>
                            <TabOne />
                            <PricingFive />
                        </div>
                </div>
                {/* End Enterprise Area  */}

                <div id="Customer360">
                    <Separator />
                </div>

                {/* Start Customer 360 Area  */}
                <div className="rwt-pricing-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            // subtitle = "Pricing"
                                            title = "Salesforce Customer 360"
                                            // description = "Application modernization is a process to update organization’s existing software’s, systems, tools to the latest current versions"
                                            />
                                </div>
                            </div>
                            <p>‣ Our expertise extends across all products, solutions, and technologies of Salesforce.com. The 
                                success of our customers forms the bedrock of our business, enabling you to grow at Lightning 
                                Speed.  
                                <br></br>‣ RIS equips enterprises with strategy, analytics and business intelligence with hands-on keyboard 
                                execution to anchorage Salesforce’s Sales & Marketing cloud, Einstein and Tableau to create 
                                customised integrated channel experiences to drive revenue. 
                                <br></br>‣ As your tech advisor, our promise is to help you by enabling and integrating your people, process 
                                and technology together to deploy a protocol based on scalable techniques, certainty and 
                                security. 
                                <br></br>‣ RIS, one of preferred Salesforce Partner, to extend the Salesforce Capabilities to help our 
                                customers to build an End-End Solutions & Services. 
                                <br></br>‣ RIS has almost 150+ Salesforce professionals, 100+ certified professionals servicing customers in 
                                different domains. 
                                <br></br>‣ We help you find the finest Salesforce professionals with minimal effort and expenditure.  
                            </p>
                            <p>
                            <b>Discover & Project Envisioning</b>
<br></br>
‣Our experts will help you observe, analyse, evaluate and assess your existing business 
process, people - technology coordination and draft plotlines along which the design has to 
be built.  
<br></br>‣Our consultants will also be able to suggest you an implementation strategy that is tailor-
made for your business and even customisable to a specific business aspect. 
<br></br>
<b>Agile Custom Development</b>
<br></br>
‣The agile custom development with a focus on process efficiencies and Innovation.  
<br></br>‣Our Salesforce Implementation is agile, that supports a package of custom enhancements 
that can revolutionize your organisational functionality by providing substantial benefits to 
your users.
<br></br>
<b>Legacy Data Migration to Salesforce</b>
<br></br>
‣RIS ensures that your legacy data is migrated to your Salesforce solution.  
<br></br>‣We understand the need, urgency and availability of your data - be it legacy or current.  
<br></br>‣Built around this - we design, develop and assess for cost-effective, enterprise-grade 
migration techniques within an efficient timeline for a reduced turnaround time and thus 
result in a greater business impact.  
<br></br>‣Our seasoned Salesforce Implementation Consultants have the right balance of knowledge 
and experience required to successfully tackle the most complex data migration challenges.  
<br></br>‣Our Salesforce Data Migration Services include data extraction, cleansing, de-duplication, 
standardization, validation, and import into Salesforce. 
Requirements for Bolt-On Integrations: - 
<br></br>‣RIS Consultants have a deeper understanding and expertise to identify solutions and 
incorporate bolt integrations to other online / cloud and conventional business- centric 
systems including those of finance.  
<br></br>‣We are also highly skilled at designing, developing customer adapters or configuring pre-built 
adapters. 
<br></br>
<b>Salesforce Support, Training & Documentation</b>
<br></br>
‣Our SME’s (Subject-matter experts) are certified, well tried and are well equipped to provide 
crucial and decisive support, including ongoing maintenance and continuous improvement 
services to continue helping your ever-changing business needs.  
<br></br>‣Our expertise in delivery also sets up to assist with your training, analytics, reporting and 
documentation-related needs.
                            </p>
                        </div>
                </div>
                {/* End Customer 360 Area  */}

                <div id="DataAnalytics">
                    <Separator />
                </div>
                
                {/* Start DataAnalytics Area  */}
                <div className="rwt-pricing-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            // subtitle = "Pricing"
                                            title = "Data Analytics & Engineering"
                                            description = "We strive to help our clients enhance their operational efficiencies, reduce time to market, improved decision, making increase credibility and improve customer retention, thereby delivering them a competitive advantage by using Data Analytics tools. With deep data-driven strategies, help businesses to the next level.  We have enabled clients across industries to explore new scopes by Data Modernization & Monetization."
                                            />
                                </div>
                            </div>
                            <PricingSix />
                        </div>
                </div>
                {/* End DataAnalytics Area  */}

                <div id="QualityEngineering">
                    <Separator />
                </div>

                {/* Start Quality Engineering Area  */}
                <div className="rwt-pricing-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Quality Engineering"
                                            description = "RIS Quality Engineering practice offer a comprehensive range of value-added testing solutions that are of the highest quality and efficiency that help our customers build quality products. We understand our client’s challenges and tailor our testing solutions to address those problems in a timely and cost-effective way."
                                            />
                                </div>
                            </div>
                            <PricingSeven />
                        </div>
                </div>
                {/* End Quality Engineering Area  */}

                <div id="ApplicationServicesandModernization">
                    <Separator />
                </div>

                {/* Start Application Services and Modernization Area  */}
                <div className="rwt-pricing-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Application Services and Modernization"
                                            description = "Application modernization is a process to update organization’s existing software’s, systems, tools to the latest current versions"
                                            />
                                </div>
                            </div>
                            <p>RIS our application modernization services help you achieve agility for an increasingly digital 
                            world. Data modernization is crucial for businesses to stay competitive in today’s digital landscape. RIS delivers cutting-edge data engineering solutions for modern cloud environments, with a 
                            focus on compliance and privacy. It addresses the migration of legacy to new applications or platforms, including the integration of new functionality to provide the latest functions to the business. It increases 
                            customer satisfaction. To overcome the hiccups of high maintenance cost, non-alignment of requirements and skills. Our deep industry domain experience and certified expertise helps business transform into 
                            successful enterprises by modernizing their legacy application with zero disruption. By following automation approach, organizations can increase performance, reduce high spending costs and dependencies.
                            </p>
                            <p>We follow below different approaches towards modernization.<br></br>
                            ‣ Migration <br></br>
                            ‣ Restructure <br></br>
                            ‣ Enhancement <br></br>
                            ‣ Replacement <br></br>
                            ‣ Automated 
                            </p>
                        </div>
                </div>
                {/* End Application Services and Modernization Area  */}

                <div id="Cloud">
                    <Separator />
                </div>

                {/* Start Cloud Area  */}
                <div className="rwt-pricing-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Cloud"
                                            />
                                </div>
                            </div>
                            <PricingEight />
                        </div>
                </div>
                {/* End Cloud Area  */}

                <div id="NextGen">
                    <Separator />
                </div>

                {/* Start Next Gen Area  */}
                <div className="rwt-pricing-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Next Gen"
                                            />
                                </div>
                            </div>
                            <p>Our Data Services enables efficient and seamless access, unification, migration, analysis, 
                            integration for organizations at every stage of data maturity. RIS is dedicated to helping clients navigate the complexities of the digital world and achieve 
                            their goals in a more efficient and effective way. Our Data Engineering services provide the plans, capabilities, and accelerators to help push 
                            your data-driven business forward. By leveraging the power of Machine learning, Deep learning and Artificial Intelligence (AI), 
                            our services will help you make data-driven, process automate that drive growth and 
                            success. Some of our AI and Data Science Services include BI & Data Visualization (Power BI, Tableau). 
                            </p>
                        </div>
                </div>
                {/* End Next Gen Area  */}

                <FooterOne />
            </main>
        </>
    )
}
export default Services;