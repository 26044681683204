import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogPropTwo from './itemProp/BlogPropTwo';
import BlogProp from './itemProp/BlogProp';
import BlogPropEvents from './itemProp/BlogPropEvents';


const BlogGridViewNews = () => {
    return (
        <>
            <SEO title="Blog List || Doob - React Business  Template" />
            <Layout>
                <BreadcrumbOne 
                    title="Events @RIS."
                    // rootUrl="/"
                    // parentUrl="Home"
                    // currentUrl="Blog List"
                />
                <div className="main-content">
                    {/* Start Blog Area  */}
                    <div className="rn-blog-area rn-section-gap">
                        <div className="container">
                            <div className="row mt_dec--30">
                                <BlogPropEvents column="col-lg-4 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
                            </div>
                        </div>
                    </div>
                    {/* End Blog Area  */}
                </div>
            </Layout>
        </>
    )
}
export default BlogGridViewNews;