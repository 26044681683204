import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiFacebook, FiHeadphones , FiInstagram, FiLinkedin, FiMail , FiMapPin, FiTwitter, FiPlus } from "react-icons/fi";
import CalltoActionFive from '../calltoaction/CalltoActionFive';

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact Phone Number</h4>
                                        <p><a href="tel:+444 555 666 777">+91 94983 38640</a></p>
                                        <p><a href="tel:+444 555 666 777">+91 63835 68590</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        <p><a href="mailto:admin@gmail.com">info@rudhrainfosolutions.com</a></p>
                                        <p><a href="mailto:example@gmail.com">contactus@rudhrainfosolutions.com</a></p>
                                        <p><a href="mailto:example@gmail.com">hr@rudhrainfosolutions.com</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPlus />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Connect Us</h4>
                                        <ul className="social-default">
                                                <li><a href="https://www.facebook.com/rudhrainfosolutions/"><FiFacebook /></a></li>
                                                <li><a href="https://twitter.com/rudhrainfosolut?lang=en"><FiTwitter /></a></li>
                                                <li><a href="https://www.instagram.com/life_at_ris/"><FiInstagram /></a></li>
                                                <li><a href="https://in.linkedin.com/company/rudhra-info-solutions"><FiLinkedin /></a></li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <CalltoActionFive />
                </div>
            </div>
        </>
    )
}
export default ContactOne;