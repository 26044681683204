import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-dropdown"><Link to="/services">Services</Link>
                <ul className="submenu">
                    <li className="has-sidereveal">
                        <Link to="/services/#EnterpriseApplication">Enterprise Application</Link>
                        <ul className="side-submenu">
                            <li><Link to="/services#EnterpriseApplication">Salesforce</Link></li>
                            <li><Link to="/services#EnterpriseApplication">Veeva/Veeva Vault</Link></li>
                            <li><Link to="/services#EnterpriseApplication">Vlocity</Link></li>
                            <li><Link to="/services#EnterpriseApplication">Dynamics 365</Link></li>
                            <li><Link to="/services#EnterpriseApplication">SAP</Link></li>
                            <li><Link to="/services#EnterpriseApplication">Service Now</Link></li>
                        </ul>
                    </li>
                    <li className="has-sidereveal">
                        <Link to="/services/#DataAnalytics">Data Analytics & Engineering</Link>
                        <ul className="side-submenu">
                            <li><Link to="/services#DataAnalytics">Tableau</Link></li>
                            <li><Link to="/services#DataAnalytics">Power BI</Link></li>
                            <li><Link to="/services#DataAnalytics">Einstein AI</Link></li>
                            <li><Link to="/services#DataAnalytics">QlikView</Link></li>
                            <li><Link to="/services#DataAnalytics">Big Data</Link></li>
                            <li><Link to="/services#DataAnalytics">Python Spark & Pandas</Link></li>
                        </ul>
                    </li>
                    <li className="has-sidereveal">
                        <Link to="/services#QualityEngineering">Quality Engineering</Link>
                        <ul className="side-submenu">
                            <li><Link to="/services#QualityEngineering">Salesforce Testing</Link></li>
                            <li><Link to="/services#QualityEngineering">SAP Testing</Link></li>
                            <li><Link to="/services#QualityEngineering">Big Data & Analytics testing</Link></li>
                            <li><Link to="/services#QualityEngineering">Continuous Testing</Link></li>
                            <li><Link to="/services#QualityEngineering">Webservices</Link></li>
                            <li><Link to="/services#QualityEngineering">Automation Testing</Link></li>
                            <li><Link to="/services#QualityEngineering">Performance Testing</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/services#ApplicationServicesandModernization">Application services & Modernization</Link></li>
                    <li><Link to="/services#Cloud">Cloud</Link></li>
                    <li><Link to="/services#NextGen">Next Gen</Link></li>
                </ul>
            </li>

            <li className="has-dropdown"><Link to="/industries">Industries</Link>
                <ul className="submenu">
                    <li><Link to="/industries#HealthCareandLifeSciences">Health Care & Life Sciences</Link></li>
                    <li><Link to="/industries#RealEstate">Real Estate</Link></li>
                    <li><Link to="/industries#RetailandECommerce">Retail & E-Commerce</Link></li>
                    <li><Link to="/industries#FinancialandBanking">Financial and Banking</Link></li>
                    <li><Link to="/industries#Insurance">Insurance</Link></li>
                    <li><Link to="/industries#Logistics">Logistics</Link></li>
                    <li><Link to="/industries#Manufacturing">Manufacturing</Link></li>
                </ul>
            </li>

            <li className="has-dropdown"><Link to="/about-us">About RIS</Link>
                <ul className="submenu">
                <li><Link to="/about-us#whoweare">Who we are</Link></li>
                <li><Link to="/about-us#corevalues">Core Values</Link></li>
                <li><Link to="/about-us#timeline">Timeline</Link></li>
                <li><Link to="/about-us#ourteam">Leaders</Link></li>
                <li><Link to="/about-us#blogs">News/Events</Link></li>
                <li><Link to="/about-us#locations">Locations</Link></li>
                </ul>
            </li>

            <li className="has-dropdown"><Link to="/careers">Careers</Link></li>
        </ul>
    )
}
export default Nav;