import React from 'react';
import { FiArrowRight } from "react-icons/fi";
const callToActionData = {
    title: "Insurance",
}

const CalltoActionTwelve = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper" style={{'opacity' : '0.7'}}>
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{callToActionData.title}</h2>
                                <h5>
                                ‣ Insurance is going digital. Whether it's streamlining business processes or enabling new operating models, we're helping our clients transform <br></br>
                                ‣ RIS enables your business to compete successfully and profitably by improving efficiency in your business processes, delivering a seamless digital experience and evolving with disruptive trends in the industry. <br></br>
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionTwelve;