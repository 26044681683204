import React from 'react';
import { FiArrowRight } from "react-icons/fi";
const callToActionData = {
    title: "Manufacturing",
}

const CalltoActionFourteen = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper" style={{'opacity' : '0.7'}}>
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{callToActionData.title}</h2>
                                <h5>
                                ‣ Our solutions help manufacturers optimize their production processes through the use of advanced analytics, machine learning, and real-time monitoring. This enables businesses to identify inefficiencies, reduce waste, and increase productivity.<br></br>
                                ‣ Our quality control solutions enable manufacturers to monitor product quality throughout the production process, from raw materials to finished products. This helps businesses detect defects, reduce scrap and rework, and improve customer satisfaction.<br></br>
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionFourteen;