import React from 'react';
import { FiArrowRight } from "react-icons/fi";
const callToActionData = {
    title: "Real Estate",
}

const CalltoActionNine = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper" style={{'opacity' : '0.7'}}>
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{callToActionData.title}</h2>
                                <h5>
                                ‣ We help you with custom Salesforce implementation such that it speaks seamlessly with your property developers, real estate agents and project marketers. <br></br>
                                ‣ Shape meaningful associations with buyers and sellers <br></br>
                                ‣ Marketing - We enable you to send engaging emails to prospects with relevant and timely messaging. We also set up routing of web form enquiries to sales reps based on assignment rules <br></br>
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionNine