import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Link} from "react-router-dom";

const dataList = [
    {
        image: '/images/service/serviice-01.jpg',
        title: 'New Branch at Whitefield Bangalore!!!',
        description: 'There are many variations variats  of passages of Lorem Ipsum available.'
    },
    {
        image: '/images/service/serviice-02.jpg',
        title: 'RIS-Dreamforce Glimpse-Day 1!!!',
        description: 'Passages there are many variatin Lorem Ipsum available have suffered.'
    }
]

const dataList2 = [
    {
        image: '/images/service/serviice-03.jpg',
        title: 'App Development',
        description: 'There are many variations variats  of passages of Lorem Ipsum available.'
    },
    {
        image: '/images/service/serviice-04.jpg',
        title: 'Business Design',
        description: 'Passages there are many variatin Lorem Ipsum available have suffered.'
    }
]

const dataList3 = [
    {
        image: '/images/service/serviice-03.jpg',
        title: 'App Development',
        description: 'There are many variations variats  of passages of Lorem Ipsum available.'
    },
    {
        image: '/images/service/serviice-04.jpg',
        title: 'Business Design',
        description: 'Passages there are many variatin Lorem Ipsum available have suffered.'
    },
    {
        image: '/images/service/serviice-04.jpg',
        title: 'Business Design',
        description: 'Passages there are many variatin Lorem Ipsum available have suffered.'
    }
]

const TabThree = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-12">
                                    <div className="rn-default-tab style-three">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button" style={{'justify-content':'centre'}}>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>News</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Events</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--30">
                                                            <div className="col-lg-4">
                                                                <div className="section-title">
                                                                    <h4 className="title">In RIS, it's always happening</h4>
                                                                    <p>Our company is always buzzing with activity and new developments. From cutting-edge product launches to exciting team-building events, we strive to keep things fresh and engaging for our employees and customers alike.</p>
                                                                    <div className="read-more">
                                                                        {/* <a className="btn-default btn-small" href="/blog-grid-news">Read More</a> */}
                                                                        <Link className="btn-default btn-small" to="/blog-grid-news">Read More</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                                                                <div className="row row--15 mt_dec--30 service-wrapper">
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="image">
                                                                                        <Link to="/blog-news-details/1">
                                                                                            <img src="/images/service/serviice-01.png" alt="card Images" />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="/blog-news-details/1">New Branch at Whitefield Bangalore!!!</Link></h4>
                                                                                        <Link className="btn-default btn-small" to="/blog-news-details/1">Read More</Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="image">
                                                                                        <Link to="/blog-news-details/2">
                                                                                            <img src="/images/service/serviice-02.png" alt="card Images" />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="/blog-news-details/2">RIS-Dreamforce Glimpse!!!</Link></h4>
                                                                                        <Link className="btn-default btn-small" to="/blog-news-details/2">Read More</Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--30">
                                                            <div className="col-lg-4">
                                                                <div className="section-title">
                                                                    <h4 className="title">We Work Hard, Play Harder</h4>
                                                                    <p>If you're looking for exciting upcoming events and activities, be sure to check out our Events section! This section is your go-to destination for information on Tech Talks, celebrations, workshops, and other happenings in RIS.</p>
                                                                    <div className="read-more">
                                                                        <Link className="btn-default btn-small" to="/blog-grid-event">Read More</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                                                                <div className="row row--15 mt_dec--30 service-wrapper">
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="image">
                                                                                        <Link to="/blog-events-details/1">
                                                                                            <img src="/images/service/serviice-03.png" alt="card Images" />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="/blog-events-details/1">RIS Welcomes New team of RISians !!!</Link></h4>
                                                                                        <Link className="btn-default btn-small" to="/blog-events-details/1">Read More</Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="image">
                                                                                        <Link to="/blog-events-details/2">
                                                                                            <img src="/images/service/serviice-04.png" alt="card Images" />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="/blog-events-details/2">Upcoming Veeva Commercial Summit!!!</Link></h4>
                                                                                        <Link className="btn-default btn-small" to="/blog-events-details/2">Read More</Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabThree
