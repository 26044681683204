import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "2012",
        title: "Birth of RIS",
        description: "Rudhra Info Solutions founded at Nungambakkam, Chennai.",
        image: "timeline-01",
    },
    {
        id: "2",
        date: "2017",
        title: "Making the Family Bigger",
        description: "Our 2nd branch office with 80+ resources at Mannivakkam, Chennai.",
        image: "timeline-05",
    },
    {
        id: "3",
        date: "2019",
        title: "Growing Exponentially",
        description: "Inaugrated our new Office at Kukatpally, Hyderabad with 150+ resources.",
        image: "timeline-02",
    },
    {
        id: "4",
        date: "2020",
        title: "Making an Impact",
        description: "Opened our 4th and Largest branch at Electronic City Phase-I, Bengaluru",
        image: "timeline-03",
    },
    {
        id: "5",
        date: "2022",
        title: "Going International",
        description: "Our first International Branch office opened at Alpharetta, GA",
        image: "timeline-04",
    },
    {
        id: "6",
        date: "2023",
        title: "One of the Best Around the Globe.",
        description: "We now have more than 310 resources at RIS and we are in our successful path as we started.",
        image: "timeline-06",
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-large round" href="/contact"><span>Click here to know more</span></a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.png`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
