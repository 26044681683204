import React from 'react';
import { FiArrowRight } from "react-icons/fi";
const callToActionData = {
    title: "Retail & E-Commerce",
}

const CalltoActionTen = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper" style={{'opacity' : '0.7'}}>
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{callToActionData.title}</h2>
                                <h5>
                                ‣ Our CRM software for retail incorporates analytics technology, customer engagement tools, AI, automation, and personalisation to help retailers create seamless customer journeys that win shopper loyalty <br></br>
                                ‣ Personalize every step of your customer journey with a single omnichannel retail engagement platform<br></br>
                                ‣ Our integrated CRM platform for retail will help you unite your teams, create compelling commerce and marketing experiences, and deliver consistent service at every moment<br></br>
                                ‣ Deliver seamless buying and service experiences across all channels by engaging with customers in the right place, at the right time<br></br>
                                ‣ Change how you serve customers by supporting your retail staff with digital tools to increase efficiency and improve service <br></br>
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionTen;