import React from 'react';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';

import Copyright from '../common/footer/Copyright';
import ScrollTop from '../common/footer/ScrollTop';
import PortfolioOne from '../elements/portfolio/PortfolioOne';
import BrandThree from '../elements/brand/BrandThree';

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BlogGridSidebar from '../components/blog/BlogGridSidebar';
import BlogGridViewCareer from '../components/blog/BlogGridViewCareer';


const Careers = () => {
    return (
        <>
            <SEO title="Careers" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider area  */}
                <div className="slider-area slider-style-7 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h4 class="subtitle rn-sub-badge"><span class="theme-gradient">Give your Career a boost </span></h4>
                                    <h1 className="title">Join RIS<br />Today.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}

                <Separator />

                <div className="rwt-portfolio-area rn-section-gapTop">
                    <div className="container-fluid plr--60 plr_md--15 plr_sm--15">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    // subtitle = "Portfolio Box Layout"
                                    title = "Opportunities"
                                    description = ""
                                />
                            </div>
                        </div>
                        <BlogGridViewCareer />
                    </div>
                </div>

                <ScrollTop />
            </main>
        </>
    )
}
export default Careers;