import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import SeparatorTwo from "../elements/separator/SeparatorTwo";
import PricingFive from '../elements/pricing/PricingFive';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import FooterOne from '../common/footer/FooterOne';
import { FiArrowRight, FiCheck } from 'react-icons/fi';
import PricingSix from '../elements/pricing/PricingSix';
import PricingSeven from '../elements/pricing/PricingSeven';
import PricingEight from '../elements/pricing/PricingEight';
import CalltoActionEight from '../elements/calltoaction/CalltoActionEight';
import CalltoActionNine from '../elements/calltoaction/CalltoActionNine';
import CalltoActionTen from '../elements/calltoaction/CalltoActionTen';
import CalltoActionEleven from '../elements/calltoaction/CalltoActionEleven';
import CalltoActionTwelve from '../elements/calltoaction/CalltoActionTwelve';
import CalltoActionThirteen from '../elements/calltoaction/CalltoActionThirteen';
import CalltoActionFourteen from '../elements/calltoaction/CalltoActionFourteen';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const Industries = () => {
    return (
        <>
            <SEO title="RIS Services" />
            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider area  */}
                <div className="slider-area slider-style-2 height-950 bg_image" data-black-overlay="2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-5.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-start">
                                    <h1 className="title color-white">Industries<br></br>We Operate In.</h1>
                                    <div className="button-group mt--40 mt_sm--20">
                                        <button className="btn-default btn-icon" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Get In Touch<i className="icon"><FiArrowRight /></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}

                <div className="rwt-elements-area rn-section-gap">
                        <div className="container">
                        <Tabs>
                            <div className="rn-default-tab">
                                <div className="tab-button-panel">
                                    <TabList className="tab-button">
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'1vw'}}><a href="#HealthCare"><b>Health Care</b></a></button>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'1vw'}}><a href="#RealEstate"><b>Real Estate</b></a></button>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'1vw'}}><a href="#Retail"><b>Retail</b></a></button>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'1vw'}}><a href="#Financial"><b>Financial</b></a></button>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'1vw'}}><a href="#Insurance"><b>Insurance</b></a></button>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'1vw'}}><a href="#Logistics"><b>Logistics</b></a></button>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <button style={{fontSize:'1vw'}}><a href="#Manufacturing"><b>Manufacturing</b></a></button>
                                            </div>   
                                        </Tab>
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                        </div>
                </div>

                <div id='HealthCare'>
                    <SeparatorTwo />
                </div>

                {/* Start Health Care & Life Sciences Area  */}
                <div className="rwt-pricing-area rn-section-gap bg_image bg_image_fixed" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-13.jpg)`}} data-black-overlay="4" id='HealthCareandLifeSciences'>
                        {/* <div className="container"> */}
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <CalltoActionEight />
                                </div>
                            </div>
                        {/* </div> */}
                </div>
                {/* End Health Care & Life Sciences Area  */}

                <div id='RealEstate'>
                    <SeparatorTwo />
                </div>

                {/* Start Real Estate Area  */}
                <div className="rwt-pricing-area rn-section-gap bg_image bg_image_fixed" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-14.jpg)`}} data-black-overlay="4" id='RealEstate'>
                        {/* <div className="container"> */}
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                    <CalltoActionNine />
                                </div>
                            </div>
                        {/* </div> */}
                </div>
                {/* End Real Estate Area  */}

                <div id='Retail'>
                    <SeparatorTwo />
                </div>

                {/* Start Retail & E-Commerce Area  */}
                <div className="rwt-pricing-area rn-section-gap bg_image bg_image_fixed" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-15.jpg)`}} data-black-overlay="4" id='RetailandECommerce'>
                        {/* <div className="container"> */}
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                    <CalltoActionTen />
                                </div>
                            </div>
                        {/* </div> */}
                </div>
                {/* End Retail & E-Commerce Area  */}

                <div id='Financial'>
                    <SeparatorTwo />
                </div>

                {/* Start Financial and Banking Area  */}
                <div className="rwt-pricing-area rn-section-gap bg_image bg_image_fixed" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-16.jpg)`}} data-black-overlay="4" id='FinancialandBanking'>
                        {/* <div className="container"> */}
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <CalltoActionEleven />
                                </div>
                            </div>
                        {/* </div> */}
                </div>
                {/* End Financial and Banking Area  */}

                <div id='Insurance'>
                    <SeparatorTwo />
                </div>

                {/* Start Insurance Area  */}
                <div className="rwt-pricing-area rn-section-gap bg_image bg_image_fixed" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-17.jpg)`}} data-black-overlay="4" id='Insurance'>
                        {/* <div className="container"> */}
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                    <CalltoActionTwelve />
                                </div>
                            </div>
                        {/* </div> */}
                </div>
                {/* End Insurance Area  */}

                <div id='Logistics'>
                    <SeparatorTwo />
                </div>

                {/* Start Logistics Area  */}
                <div className="rwt-pricing-area rn-section-gap bg_image bg_image_fixed" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-18.jpg)`}} data-black-overlay="4" id='Logistics'>
                        {/* <div className="container"> */}
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                    <CalltoActionThirteen />
                                </div>
                            </div>
                        {/* </div> */}
                </div>
                {/* End Logistics Area  */}

                <div id='Manufacturing'>
                    <SeparatorTwo />
                </div>

                {/* Start Manufacturing Area  */}
                <div className="rwt-pricing-area rn-section-gap bg_image bg_image_fixed" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-19.jpg)`}} data-black-overlay="4" id='Manufacturing'>
                        {/* <div className="container"> */}
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                    <CalltoActionFourteen />
                                </div>
                            </div>
                        {/* </div> */}
                </div>
                {/* End Manufacturing Area  */}

                <FooterOne />
            </main>
        </>
    )
}
export default Industries;