import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";


const SlpitOne = () => {
    return (
            <div className="rn-splite-style">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-center">
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="thumbnail image-left-content">
                                    <img src="./images/split/split-01.jpg" alt="split Images" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="title">Who We Are</h4>
                                    </ScrollAnimation>
                                    {/* <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">At RIS, we firmly believe in our infinite potential. We understand that the possibilities for innovation and progress are limitless, and we are committed to pushing the boundaries of what is possible. This empowers us to take calculated risks and approach problems with a creative and innovative mindset. As we continue to grow and expand our company, we remain steadfast in our belief that our potential is infinite, and that we have the power to make a lasting impact in the world of technology.</p>
                                    </ScrollAnimation> */}

                                    
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <ul className="split-list">
                                            <li>‣ Inspired by values & potential, our aim is to always positively impact our stakeholders, partners, clients and people at large.</li>
                                            <li>‣ RIS started with a team of 3 passionate individuals having a vision of creating a Salesforce consultancy and development company which focuses on solving complex business problems in the most efficient way</li>
                                            <li>‣ Founded in the year 2012, are highly caliber and possess capabilities to develop and implement any Salesforce CRM application</li>
                                            <li>‣ We are well established in the market and our clients known for our accuracy, reliability, high performance and delivering the best</li>
                                        </ul>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <div className="view-more-button mt--35">
                                            <a className="btn-default" href="#getintouch">Contact With Us</a>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SlpitOne
