import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/galery-image-01.jpg',
        title: 'Innovation',
        description: 'We are driven by constant Innovation and strive for Perfection.'
    },
    {
        image: '/images/service/galery-image-02.jpg',
        title: 'Trust',
        description: 'We believe in Supporting your Mission and never take your Trust for granted.'
    },
    {
        image: '/images/service/galery-image-03.png',
        title: 'Sharp',
        description: 'We bring the most value when we serve as a Strategic Partner and Trusted Provider.'
    }
]
const ServiceFive = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="content">
                                    <h4 className="title" dangerouslySetInnerHTML={{__html: val.title}}></h4>
                                    <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                                {/* <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div> */}
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceFive;