import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";

const SlipFour = () => {
    return (
        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h4 className="title">Who We Are</h4>
                            </ScrollAnimation>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <ul className="split-list">
                                            <li>‣ Inspired by values & potential, our aim is to always positively impact our stakeholders, partners, clients and people at large.</li>
                                            <li>‣ RIS started with a team of 3 passionate individuals having a vision of creating a Salesforce consultancy and development company which focuses on solving complex business problems in the most efficient way</li>
                                            <li>‣ Founded in the year 2012, are highly caliber and possess capabilities to develop and implement any Salesforce CRM application</li>
                                            <li>‣ We are well established in the market and our clients known for our accuracy, reliability, high performance and delivering the best</li>
                                        </ul>
                            </ScrollAnimation>
                            <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <div className="view-more-button mt--35">
                                            <a className="btn-default" href="#getintouch">Contact With Us</a>
                                        </div>
                            </ScrollAnimation>

                            <div className="row">
                                {/* {Data.map((data, index) => (
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                        <div className="count-box counter-style-4 text-start">
                                            <TrackVisibility once>
                                                {({ isVisible }) => isVisible && 
                                                    <div className="count-number">{isVisible ? <CountUp end={data.countNum} /> : 0}</div>}
                                            </TrackVisibility>
                                            <h5 className="counter-title">{data.countTitle}</h5>
                                        </div>
                                    </div>
                                ))} */}
                            </div>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src="./images/split/split-01.jpg" alt="split Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipFour;