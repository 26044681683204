import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';
import { FiCheck } from 'react-icons/fi';

const AccordionSix = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="1">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">SAP</Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                    <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck />RIS SAP practice helps business to accelerate their digital transformation by SAP certified tools. Our deep industry domain experience and certified expertise and years of experience across countless partners, and applications suite products successfully</li>
                            </ul>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            {/* <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                We are a team of great dreamers
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>We understand that in order to create something truly revolutionary, it takes a team of individuals who are willing to think big and take risks. Our team is made up of visionaries who are not afraid to dream big and pursue bold ideas. We believe that through our collective creativity and dedication, we can make a real difference in the world of technology. Our great dreaming mentality pushes us to constantly challenge the status quo and explore new ways of thinking about technology. It drives us to think beyond the immediate needs of our clients, and to focus on creating solutions that have the potential to transform entire industries. By embracing our inner dreamers, we are able to cultivate an environment that fosters innovation, collaboration, and ultimately, success.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                We are a strategic partner
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Serving as a strategic partner adds our expertise to yours in ways that leverage our respective strengths to accelerate your growth. We bring the most value when we serve as both a strategic partner addressing your most complex challenges and as a trusted provider.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                We deliver extraordinary service
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Our industry-leading solutions maximize efficiencies across the IT landscape - from strategic consulting and commercial solutions to IT outsourcing and more.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                Our clients always come first
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>Our clients mean the world to us. We believe in supporting your mission and never take your trust for granted.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                We are driven by constant innovation and strive for perfection.
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body>We understand that technology is constantly evolving, and that in order to stay at the forefront of our industry, we must always be looking for ways to improve and push the boundaries of what is possible. Our team is made up of passionate individuals who are constantly seeking out new ideas, tools, and methodologies to help us create the most innovative and effective solutions for our clients. We are not satisfied with just meeting our clients' expectations - we want to exceed them. Our commitment to perfection drives us to rigorously test and refine our products and services until they are flawless.</Card.Body>
                </Accordion.Collapse>
            </Card> */}

        </Accordion>
    )
}
export default AccordionSix

