import React from 'react';
import { FiArrowRight } from "react-icons/fi";
const callToActionData = {
    title: "Financial and Banking",
}

const CalltoActionEleven = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper" style={{'opacity' : '0.7'}}>
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{callToActionData.title}</h2>
                                <h5>
                                ‣ Automate the complete financial experience<br></br>
                                ‣ We combine digital innovation, operational optimization, and a best-in-class fintech ecosystem to create customer experiences that keep pace with the transformation in the retail banking landscape<br></br>
                                ‣ We use new technologies to help banks and payment providers take bold steps to thrive and be future-ready<br></br>
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionEleven;