import React from 'react';
import AccordionEight from '../accordion/AccordionEight';
import AccordionEighteen from '../accordion/AccordionEighteen';
import AccordionEleven from '../accordion/AccordionEleven';
import AccordionFifteen from '../accordion/AccordionFifteen';
import AccordionFive from '../accordion/AccordionFive';
import AccordionFour from '../accordion/AccordionFour';
import AccordionFourteen from '../accordion/AccordionFourteen';
import AccordionNine from '../accordion/AccordionNine';
import AccordionNineteen from '../accordion/AccordionNineteen';
import AccordionSeven from '../accordion/AccordionSeven';
import AccordionSeventeen from '../accordion/AccordionSeventeen';
import AccordionSix from '../accordion/AccordionSix';
import AccordionSixteen from '../accordion/AccordionSixteen';
import AccordionTen from '../accordion/AccordionTen';
import AccordionThirteen from '../accordion/AccordionThirteen';
import AccordionThree from '../accordion/AccordionThree';
import AccordionTwelve from '../accordion/AccordionTwelve';
import AccordionTwenty from '../accordion/AccordionTwenty';
import AccordionTwentyOne from '../accordion/AccordionTwentyOne';
import AccordionTwo from '../accordion/AccordionTwo';


const PricingSeven = () => {
    return (
        <div className="row row--15">

            {/* Start Accordion Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionFourteen />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        < AccordionFifteen />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionSixteen />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionSeventeen />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionEighteen />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionNineteen />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionTwenty />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionTwentyOne />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

        </div>
    )
}
export default PricingSeven;
