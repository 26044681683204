import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";

const Data = [
    {
        countNum : 300,
        countTitle: 'Engineers and Developers.',
    },
    {
        countNum : 5,
        countTitle: 'Offices Globally',
    },
    {
        countNum : 12,
        countTitle: 'Years in Field',
    },
];

const SlipTwo = () => {
    return (
        <div className="rn-splite-style">
            <div className="split-wrapper">
                    <div className="row no-gutters radius-10 align-items-center">
                        <div className="col-lg-12 col-xl-6 col-12">
                            <div className="thumbnail">
                                <img src="./images/split/split-02.png" alt="split Images" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-6 col-12">
                            <div className="split-inner">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="title">We Believe in Our Infinite Potential</h4>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p className="description">At RIS, we firmly believe in our infinite potential. We understand that the possibilities for innovation and progress are limitless, and we are committed to pushing the boundaries of what is possible. This empowers us to take calculated risks and approach problems with a creative and innovative mindset. As we continue to grow and expand our company, we remain steadfast in our belief that our potential is infinite, and that we have the power to make a lasting impact in the world of technology.</p>
                                </ScrollAnimation>

                                <div className="row">
                                    {Data.map((data, index) => (
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                            <div className="count-box counter-style-4 text-start">
                                                <TrackVisibility once>
                                                    {({ isVisible }) => isVisible && 
                                                        <div className="count-number">{isVisible ? <CountUp end={data.countNum} /> : 0}</div>}
                                                </TrackVisibility>
                                                <h5 className="counter-title">{data.countTitle}</h5>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipTwo;