import React from 'react'
import Typed from 'react-typed';
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BrandOne from "../elements/brand/BrandOne";
import TabThree from "../elements/tab/TabThree";
import CalltoActionOne from "../elements/calltoaction/CalltoActionOne";
import CalltoActionTwo from "../elements/calltoaction/CalltoActionTwo"; 
import SlipTwo from "../elements/split/SlipTwo";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import ServiceThree from "../elements/service/ServiceThree";
import { FiArrowDown } from "react-icons/fi";
import FooterOne from '../common/footer/FooterOne';
import BrandFive from '../elements/brand/BrandFive';
import ServiceSix from '../elements/service/ServiceSix';
import AccordionTwentyFive from '../elements/accordion/AccordionTwentyFive';
import AccordionTwentySix from '../elements/accordion/AccordionTwentySix';
import AccordionTwentySeven from '../elements/accordion/AccordionTwentySeven';
import {Link} from "react-router-dom";

const LandingPage = () => {
    return (
        <>
           <SEO title="RIS" />
           <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle"></span>
                                    <h1 className="title theme-gradient display-two">One stop solution for <br /> {" "}
                                        <Typed
                                            strings={[
                                                "Development.",
                                                "Consulting.",
                                                "Data Analytics.",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={10}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <h4 style={{'color' : 'grey'}}>Rudhra Info Solutions (RIS) is a Global Technology Consulting and Services Company specializing in Customer-Centric End-to-End Digital Experience, Supporting Businesses to become connected, transformation, open & innovative.</h4>
                                    <div className="button-group">
                                        {/* <a className="btn-default btn-medium round btn-icon" target="_blank" href="/about-us#getintouch">Get In Touch<i className="icon"><FiArrowRight /></i></a> */}
                                        <Link className="btn-default btn-medium round btn-icon" to="/about-us#getintouch">Get In Touch<i className="icon"><FiArrowRight /></i></Link>
                                        <a className="btn-default btn-medium btn-border round btn-icon" href="#WelcomeNote">Know More <FiArrowDown /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-split-area rn-section-gap" id='WelcomeNote'>
                    <div className="wrapper">
                        <SlipTwo />
                        <ServiceSix serviceStyle = "gallery-style" textAlign = "text-start"/>
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                <div className="row row--15">

                                {/* Start Accordion Area  */}
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="rn-pricing style-5 active">
                                        <div className="pricing-table-inner">
                                            <AccordionTwentyFive />
                                        </div>
                                    </div>
                                </div>
                                {/* End Accordion Table Area  */}

                                {/* Start Accordion Area  */}
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="rn-pricing style-5 active">
                                        <div className="pricing-table-inner">
                                            <AccordionTwentySix />
                                        </div>
                                    </div>
                                </div>
                                {/* End Accordion Table Area  */}

                                {/* Start Accordion Area  */}
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="rn-pricing style-5 active">
                                        <div className="pricing-table-inner">
                                            <AccordionTwentySeven />
                                        </div>
                                    </div>
                                </div>
                                {/* End Accordion Table Area  */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Clients Area  */}
                <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = "" 
                                        // subtitle = "Our clients always come first"
                                        title = "Our Clients"
                                        //description = "Our clients mean the world to us. We believe in supporting your mission and never take your trust for granted."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--40">
                                    <BrandOne brandStyle="brand-style-1" />
                                </div>
                            </div>
                        </div>
                </div>
                {/* End Clients Area  */}

                <Separator />

                {/* Start Testimonal Area  */}
                <div className="rwt-elements-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--20">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        // subtitle = "And what they say about us..."
                                        title = "Client Testimonials"
                                    />
                                </div>
                            </div>
                            <TestimonialThree teamStyle="" />
                        </div>
                </div>
                {/* End Testimonal Area  */}

                <Separator />

                {/* Start Partners Area  */}
                <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        title = "Our Partners and Expertise"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--40">
                                    <BrandFive brandStyle="brand-style-1" />
                                </div>
                            </div>
                        </div>
                </div>
                {/* End Partners Area  */}
                
                <Separator />

                {/* Start Blogs/Events/News Area  */}
                <div className="rwt-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "and get to know more"
                                        title = "Dive right In"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <TabThree />
                        </div>
                </div>
                {/* End Blogs/Events/News Area  */}

                {/* Start headerpoints Area  */}
                <div className="rn-service-area rn-section-gap " id="headerpoints">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                </div>
                            </div>
                            <ServiceThree 
                                serviceStyle = "service__style--2"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                {/* End headerpoints Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gap">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                </div>
                            </div>
                            <CalltoActionOne />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                    {/* Start Elements Area  */}
                    <div className="rwt-callto-action-area rn-section-gapBottom">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                </div>
                            </div>
                            <CalltoActionTwo />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                <FooterOne />
           </main>

        </>
    )
}

export default LandingPage;