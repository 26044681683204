import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'team-dark-02',
        name: 'India - Chennai',
        URL: 'https://goo.gl/maps/WinQEfcnD5yAWd5Y6',
        location: 'Plot No.3, Nisha Towers, Loganathan Nagar, 1st floor, Mannivakkam, Chennai - 600048', 
    },
    {
        image: 'team-dark-03',
        name: 'India - Bengaluru',
        URL: 'https://goo.gl/maps/WqQSJ6YDk2TnVxTF6',
        location: 'Rd Number 7, Whitefield, Vijayanagar, KIADB Export Promotion Industrial Area, Bengaluru, Karnataka 560066', 
    },
    {
        image: 'team-dark-04',
        name: 'India - Hyderabad',
        URL: 'https://goo.gl/maps/LDxZQxGtUdXSxaHMA',
        location: 'H.NO:9/HIG-A&10/HIG, Vasista Bhavan, 4th Floor, Opp. lane to DLF Cyber City, APHB Colony, Indira Nagar, Gachibowli, Hyderabad, Telangana - 500 032',  
    },
    {
        image: 'team-dark-05',
        name: 'And Expanding...',
        URL: '/blog-grid-event',
        location: 'We will be Available at more Locations very soon.',  
    },
]


const TeamFour = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className={`rn-team ${teamStyle}`}>
                                <div className="inner">
                                    <figure className="thumbnail">
                                        <img src={`./images/team/${data.image}.png`} alt="Corporate React Template" />
                                    </figure>
                                    <figcaption className="content">
                                        <div className="team-info">
                                            <h2 className="title">{data.name}</h2>
                                            {(index<3?(<h6 className="subtitle theme-gradient"><a href={data.URL}>Link to Google Map</a></h6>):(<h6 className="subtitle theme-gradient"><a href={data.URL}>Click here to Know More</a></h6>))}
                                            <div className="team-form">
                                                <span className="location">{data.location}</span>
                                            </div>
                                        </div>
                                    </figcaption>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
            ))}
        </div>
    )
}
export default TeamFour;
