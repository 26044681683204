import React from 'react';
import AccordionEight from '../accordion/AccordionEight';
import AccordionEleven from '../accordion/AccordionEleven';
import AccordionFive from '../accordion/AccordionFive';
import AccordionFour from '../accordion/AccordionFour';
import AccordionNine from '../accordion/AccordionNine';
import AccordionSeven from '../accordion/AccordionSeven';
import AccordionSix from '../accordion/AccordionSix';
import AccordionTen from '../accordion/AccordionTen';
import AccordionThirteen from '../accordion/AccordionThirteen';
import AccordionThree from '../accordion/AccordionThree';
import AccordionTwelve from '../accordion/AccordionTwelve';
import AccordionTwentyFour from '../accordion/AccordionTwentyFour';
import AccordionTwo from '../accordion/AccordionTwo';


const PricingSix = () => {
    return (
        <div className="row row--15">

            {/* Start Accordion Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionSeven />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        < AccordionEight/>
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionNine />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionTen />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            {/* <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionEleven />
                    </div>
                </div>
            </div> */}
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionTwelve />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionThirteen />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

            {/* Start Accordion Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <AccordionTwentyFour />
                    </div>
                </div>
            </div>
            {/* End Accordion Table Area  */}

        </div>
    )
}
export default PricingSix;
