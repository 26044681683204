import React from 'react';
import { FiArrowRight } from "react-icons/fi";
import {Link} from "react-router-dom";

const callToActionData = {
    title: "Or Come Join Us",
    subtitle: "Give your career a boost. Join RIS Today.",
    btnText: "See Openings",
}

const CalltoActionTwo = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`}} data-black-overlay="8">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title mb--0">{callToActionData.title}</h2>
                                <p>Give your career a boost. Join RIS Today.</p>
                                <div className="call-to-btn text-center">
                                    <Link className="btn-default" to="/careers">{callToActionData.btnText}<i className="icon"><FiArrowRight /></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionTwo;