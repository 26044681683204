import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TeamFour from "../elements/team/TeamFour";
import Separator from "../elements/separator/Separator";
import SlipFour from "../elements/split/SlipFour";
import SlpitOne from "../elements/split/SlpitOne";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import TeamThree from "../elements/team/TeamThree";
import AdvanceTabOne from "../elements/advancetab/AdvanceTabOne";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FooterOne from '../common/footer/FooterOne';
import ContactOne from '../elements/contact/ContactOne';
import AdvanceTabTwo from '../elements/advancetab/AdvanceTabTwo';
import AdvanceTabFour from '../elements/advancetab/AdvanceTabFour';
import AdvanceTabFive from '../elements/advancetab/AdvanceTabFive';
import TeamFive from '../elements/team/TeamFive';
import TeamSix from '../elements/team/TeamSix';
import TabThree from '../elements/tab/TabThree';
import ServiceSeven from '../elements/service/ServiceSeven';
import CalltoAction from '../elements/calltoaction/CalltoAction';
import CalltoActionThree from '../elements/calltoaction/CalltoActionThree';

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="4" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <h1 className="title display-one">We are<br></br><span className="theme-gradient">R</span>eady to face Challenges,<br></br>driven by constant <span className="theme-gradient">I</span>nnovation and<br></br><span className="theme-gradient">S</span>trive for Perfection</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <div className="rwt-elements-area rn-section-gap">
                        <div className="container">
                        <Tabs>
                            <div className="rn-default-tab">
                                <div className="tab-button-panel">
                                    <TabList className="tab-button">
                                        <Tab>
                                            <div className="rn-tab-button">
                                                <b><a href="#whoweare">Who we are</a></b>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                            <b><a href="#corevalues">Core Values</a></b>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                            <b><a href="#timeline">Timeline</a></b>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                            <b><a href="#ourteam">Leaders</a></b>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                            <b><a href="#locations">Locations</a></b>
                                            </div>   
                                        </Tab>
                                        <Tab>
                                            <div className="rn-tab-button">
                                            <b><a href="#blogs">News/Events</a></b>
                                            </div>   
                                        </Tab>
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                        </div>
                </div>
                
                <div id="whoweare">
                    <Separator />
                </div>

                <div className="rwt-split-area rn-section-gap">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    {/* <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Split"
                                        title = "Split Style One"
                                        description = ""
                                    /> */}
                                </div>
                            </div>
                            <SlipFour />
                        </div>
                </div>

                <div id="corevalues">
                    <Separator />
                </div>

                {/* Start Core Values Area  */}
                <div className="row mb--40">
                    <div className="col-lg-12">
                        <SectionTitle
                            textAlign = "text-center"
                            radiusRounded = ""
                            // subtitle = "We belives in that bring us together as a team and as a company"
                            title = "Core Values"
                            description = ""
                        />
                    </div>
                </div>

                <div className="bg_image bg_image_fixed"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-20.jpg)`}} data-black-overlay="5">
                <div className="rwt-advance-tab-area rn-section-gap">
                        <div className="container">                           
                            <AdvanceTabOne />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <AdvanceTabTwo />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <AdvanceTabFour />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <AdvanceTabFive />
                        </div>
                </div>
                </div>
                {/* End Core Values Area  */}

                <div>
                    <Separator />
                </div>

                <ServiceSeven
                    serviceStyle = "gallery-style"
                    textAlign = "text-start"
                />

                <div id="timeline">
                    <Separator />
                </div>

                {/* Start Timeline Area  */}
                <div className="rwt-timeline-area rn-section-gap">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "How RIS became what it is today"
                                            title = "Timeline"
                                        />
                                </div>
                            </div>

                            {/* <div className="row"> */}
                                {/* <div className="col-lg-12 h-25"> */}
                                    <TimelineTwo />
                                {/* </div> */}
                            {/* </div> */}
                        {/* </div> */}
                </div>
                {/* End Timeline Area  */}

                <div id="ourteam">
                    <Separator />
                </div>

                {/*Start Team Area*/}
                <div className="rwt-team-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--15">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Management"
                                            title = "Our Team"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <TeamThree column="col-lg-3 col-md-6 col-12 mt--30" teamStyle="team-style-two" />
                            {/* <div className="row">
                                <div className="col-lg-12 mb--15">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Leaders"
                                            // title = "Our Team"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <TeamFive column="col-lg-3 col-md-6 col-12 mt--30" teamStyle="team-style-two" />
                            <TeamSix column="col-lg-3 col-md-6 col-12 mt--30" teamStyle="team-style-two" /> */}
                        </div>
                </div>
                {/*End Team Area*/}

                <div id="blogs">
                    <Separator />
                </div>
                {/* <CalltoActionThree /> */}

                {/* Start Blogs Area  */}
                <TabThree />
                {/* End Blogs Area  */}

                <div id="locations">
                    <Separator />
                </div>
                
                {/* Start Elements Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="wrapper plr--65 plr_sm--15">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "United States"
                                        title = "Locations"
                                        description = ""
                                    />
                            </div>
                        </div>
                        <CalltoActionThree />
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "India"
                                        // title = "Locations"
                                        // description = ""
                                    />
                            </div>
                        </div>
                        <TeamFour column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" teamStyle="team-style-three" />
                    </div>
                </div>
                {/* End Elements Area  */}

                <div id="getintouch">
                    <Separator />
                </div>

                {/* Start Contact Area  */}
                <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        // subtitle = "Contact Form"
                                        title = "Get In Touch"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}

                <FooterOne />
            </main>
        </>
    )
}

export default AboutUs;
