import React from 'react';
import { FiArrowRight } from "react-icons/fi";
const callToActionData = {
    title: "Health Care & Life Sciences",
}

const CalltoActionEight = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper" style={{'opacity' : '0.7'}}>
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{callToActionData.title}</h2>
                                <h5>
                                ‣ The Salesforce world’s #1 CRM reimagined for healthcare and life sciences.  <br></br>
                                ‣ We help our clients by Our offerings in digital health, operational transformation and health 
                            experience, and high impact consulting help companies deliver personalized, efficient and 
                            informed care <br></br>
                            ‣ Salesforce provides a platform that connects conversations, devices, processes, services, and 
                            patient data in a whole new way <br></br>
                            ‣ Using our expertise of working on this platform and our understanding of the domain, we 
                            streamline patient relationship management delivering more personalized, efficient care <br></br>
                            ‣ Our expertise improves patient care and health outcomes with innovative services<br></br>
                            ‣ Our Team works with industry experts in public health, medical IT, medicine, health research 
                            to develop live saving solutions<br></br>
                            ‣ We bring advanced approaches and technology solutions for global life sciences 
                            companies across the entire product lifecycle, reducing time-to-market, 
                            accelerating research and drug discovery, and optimizing commercial operations 
                            to drive top-line growth<br></br>
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionEight;