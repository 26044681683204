import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        description: "RIS deployed resources and proactively contributed their expertise to quickly and effectively transform from legacy systems to modernize application. Kudos to entire team.",
        name: "A Leading Insurance Company",
        subtitle: "App Developer",
        image: "bg-image-17"
    },
    {
        description: "RIS has helped us for Salesforce implementation within short span of period in the areas which are yet to be defined in the market. Within three weeks, we had our data statistics functioning.",
        name: "A Leading Real Estate Company",
        subtitle: "App Developer",
        image: "bg-image-14"
    },
    {
        description: "The team supports us from last 4 years with dedication & passion as same as from initial days. We call them as our extended team. A valuable engineering team.",
        name: "A Leading Pharmacy Company",
        subtitle: "App Developer",
        image: "bg-image-13"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <p>{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/bg/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















